<template>
  <v-container>
    <perseu-card title="Estado civil">
      <v-btn color="secondary" slot="title-right" @click="create">
        Novo Estado Civil
      </v-btn>
      <v-flex slot="content">
        <v-switch v-model="query.active" label="Ativos"></v-switch>
        <v-data-table :items="items" :headers="headers" class="elevation-1">
          <template v-slot:item.actions="props">
            <table-action-button
              @click="edit(props.item)"
              icon="edit"
              text="Editar"
            />
            <table-action-button
              icon="delete"
              text="Desativar"
              v-if="props.item.active"
              @click="activeOrDeactivate(props.item, false)"
            />
            <table-action-button
              v-else
              icon="check"
              text="Ativar"
              @click="activeOrDeactivate(props.item, true)"
            />
          </template>
        </v-data-table>
      </v-flex>
    </perseu-card>
    <v-dialog v-model="dialog" v-if="dialog" width="600px">
      <form-marital-status
        @create="createdForm"
        @update="updatedForm"
        :value="item"
      ></form-marital-status>
    </v-dialog>
  </v-container>
</template>

<script>
import * as service from "@/services/marital-status-service";
export default {
  components: {
    "form-marital-status": () => import("@/components/MaritalStatus/Form"),
  },
  data: () => ({
    headers: [
      { text: "Nome", value: "name" },
      { text: "Idioma", value: "lang.name" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "start",
        width: 200,
      },
    ],
    items: [],
    item: {},
    dialog: false,
    query: {
      active: true,
    },
  }),
  watch: {
    query: {
      handler() {
        this.list();
      },
      deep: true,
    },
  },
  computed: {
    title() {
      return this.item._id ? "Editar Estado Civil" : "Novo Estado Civil";
    },
    editItem() {
      return this.item;
    },
  },
  methods: {
    async activeOrDeactivate(item, option) {
      try {
        this.$store.dispatch("loading/openDialog");
        item.active = option;
        await service.save(item);
        const index = this.findIndexItem(item);
        this.items.splice(index, 1);
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async list() {
      try {
        this.$store.dispatch("loading/openDialog");
        let response = await service.find(this.query);
        this.items = response.data;
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async create() {
      this.item = {};
      this.dialog = true;
    },
    createdForm(maritalstatus) {
      this.items.push(maritalstatus);
      this.dialog = false;
    },
    updatedForm() {
      this.dialog = false;
    },
    edit(item) {
      this.item = item;
      this.dialog = true;
    },
    findIndexItem(itemParam) {
      return this.items.findIndex((item) => item.id === itemParam.id);
    },
  },
  created() {
    this.list();
  },
};
</script>

<style></style>
