import api from "./api-service";

const url = "marital-status";

export const find = () => {
  return api().get(url);
};

export const save = (ms) => {
  return api().post(url, ms);
};
